import * as React from "react"
import { Link } from "gatsby"
import { Layout } from "../components/layout"

const NotFoundPage = () => {
  return (
    <Layout metaProps={{title: 'Page not found'}}>
      <section style={{minHeight: '66vh'}}>
        <div className="shell shell-fluid">
          <div className="range range-condensed">
            <div className="cell-sm-7 cell-md-preffix-1 cell-md-7 cell-lg-6 cell-xl-4">
              <h3 className="text-half-baked">Page Not Found</h3>
              <p className="text-extra-large">404</p>
              <h5 style={{ maxWidth: '600px' }} className="text-silver offset-top-0">
                The page requested couldn't be found - this could be due to a spelling error in the URL or a removed page.
                </h5>
                <Link to="/" className="btn btn-white-outline offset-xl-top-60">back to home page</Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default NotFoundPage;
